import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../state/store";
import removeFalsyObjectProperty from "../helpers/utils/removeFalsyObjectProperty";
import { selectFunnelTemplateState } from "../state/features/funnelTemplate/funnelTemplateSelector";
import {
  deselectAllTemplate,
  fetchTemplate,
  resetTemplateState,
  selectedAllTemplate,
} from "../state/features/funnelTemplate/funnelTemplateSlice";

const useFunnelTemplates = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading, currentPage, limit, count, renderId, filters, selectedTemplateIds } =
    useSelector(selectFunnelTemplateState);
  const { searchText, inTrash, tagIds, typeIds, categoryId } = filters;

  useEffect(() => {
    const payload = removeFalsyObjectProperty({
      currentPage: renderId ? currentPage : 1,
      limit,
      categoryId,
      searchText,
      inTrash,
      tagIds: tagIds.join(","),
      typeIds: typeIds.join(","),
    });
    dispatch(fetchTemplate(payload));
  }, [dispatch, searchText, inTrash, tagIds, typeIds, categoryId, renderId, currentPage, limit]);

  useEffect(() => {
    return () => {
      dispatch(resetTemplateState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePage = (newPage: number) => {
    const payload = removeFalsyObjectProperty({
      currentPage: newPage,
      limit,
      categoryId,
      searchText,
      inTrash,
      tagIds: tagIds.join(","),
      typeIds: typeIds.join(","),
    });
    dispatch(fetchTemplate(payload));
  };

  const onChangePerPage = (perPage: number, newPage: number) => {
    const payload = removeFalsyObjectProperty({
      currentPage: newPage,
      limit: perPage,
      categoryId,
      searchText,
      inTrash,
      tagIds: tagIds.join(","),
      typeIds: typeIds.join(","),
    });
    dispatch(fetchTemplate(payload));
  };

  const onClickCheckBox = () => {
    selectedTemplateIds.length === 0 ? dispatch(selectedAllTemplate()) : dispatch(deselectAllTemplate());
  };

  return {
    data,
    isLoading,
    selectedTemplateIds,
    currentPage,
    limit,
    count,
    inTrash,
    onChangePage,
    onChangePerPage,
    onClickCheckBox,
  };
};

export default useFunnelTemplates;
