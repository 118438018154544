import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import InputWithLabel from "../../common/TextField/InputWithLabel";
import { selectFunnelState } from "../../state/features/funnel/funnelSelector";
import { addFunnelFromTemplateLibrary, resetAddFunnelSuccess } from "../../state/features/funnel/funnelSlice";
import { IAddFunnelFromTemplateLibraryPayload } from "../../state/features/funnel/funnel.interface";

const makeDefaultSubdomain = (websiteName: string) => {
  return websiteName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase() + ".crmconnection.io";
};
const getError = (name: string) => {
  if (name.trim() === "") {
    return "Website name is empty!";
  } else if (name.length > 50) {
    return "Website name cannot be longer than 50 characters";
  } else {
    return "";
  }
};

interface IProps {
  onClose: () => void;
  funnelId: string;
}

const CloneFunnelModal: React.FC<IProps> = ({ onClose, funnelId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { addedFunnelInfo, isAddSuccess, isAdding } = useSelector(selectFunnelState);
  const [funnelName, setFunnelName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (isAddSuccess) {
      onClose();
      if (addedFunnelInfo) {
        dispatch(resetAddFunnelSuccess());
        history.push(`/wbuilder/funnel/${addedFunnelInfo.id}`);
      }
    }
  }, [isAddSuccess]);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(getError(e.target.value));
    setFunnelName(e.target.value);
  };

  const handleTemplateSelect = () => {
    const errorMessage = getError(funnelName);
    setError(errorMessage);
    if (!errorMessage) {
      let payload: IAddFunnelFromTemplateLibraryPayload = {
        funnelId,
        name: funnelName,
        subDomain: makeDefaultSubdomain(funnelName),
      };
      dispatch(addFunnelFromTemplateLibrary(payload));
    }
  };

  return (
    <Stack justifyContent={"space-between"} p={3} width={500} minHeight={200}>
      <InputWithLabel
        required={true}
        fullWidth={true}
        color={"secondary"}
        label={"Funnel Name"}
        value={funnelName}
        placeholder={"Give your new funnel a name"}
        onChange={handleChangeName}
        error={!!error}
        helperText={error}
        sx={{ "& .MuiInputBase-root": { bgcolor: "common.white" } }}
      />

      {funnelName !== "" && (
        <Typography variant={"caption"} noWrap component={"p"} pt={0.5}>
          https://{makeDefaultSubdomain(funnelName)}
        </Typography>
      )}
      <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} pt={3}>
        <CoreButton color={"inherit"} onClick={onClose}>
          Cancel
        </CoreButton>
        <LoadingButton
          size={"medium"}
          variant={"contained"}
          color={"secondary"}
          isLoading={isAdding}
          onClick={handleTemplateSelect}
        >
          {isAdding ? "Cloning..." : "Clone"}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default CloneFunnelModal;
