import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CoreButton from "../../../../common/Button/CoreButton";
import { fetchType } from "../../../../state/features/funnel-type/funnelTypeSlice";
import { selectFunnelTypeState } from "../../../../state/features/funnel-type/funnelTypeSelector";
import { toggleType } from "../../../../state/features/funnel-template-library/funnelTemplateLibrarySlice";
import { selectFunnelTemplateLibraryFilterState } from "../../../../state/features/funnel-template-library/funnelTemplateLibrarySelector";

const TypesOfFunnels: React.FC = () => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(5);
  const [expanded, setExpanded] = useState(false);
  const { data: typeList } = useSelector(selectFunnelTypeState);
  const { typeIds } = useSelector(selectFunnelTemplateLibraryFilterState);

  const handleSeeMore = () => {
    setLimit((prevState) => prevState + 5);
  };

  useEffect(() => {
    dispatch(fetchType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectType = (typeId: string) => {
    dispatch(toggleType(typeId));
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ background: "transparent", boxShadow: "none", "&:before": { content: "none" } }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          sx={{
            p: 0,
            minHeight: "36px !important",
            "& .MuiAccordionSummary-content.Mui-expanded": { margin: "12px 0" },
          }}
        >
          <Typography variant={"body2SemiBold"} color={"text.secondary"}>
            Types
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <List dense sx={{ pt: 0 }}>
            {typeList.map((typeInfo) => {
              const isChecked = typeIds.indexOf(typeInfo.id) !== -1;

              return (
                <ListItemButton
                  key={typeInfo.id}
                  selected={isChecked}
                  sx={{ fontSize: "13px", borderRadius: "4px", mb: 0.25 }}
                  onClick={() => handleSelectType(typeInfo.id)}
                >
                  <ListItemIcon sx={{ minWidth: "unset" }}>
                    <Checkbox edge='start' size={"small"} color={"secondary"} sx={{ p: 0.5 }} checked={isChecked} />
                  </ListItemIcon>
                  <span className={"text-truncate"} style={{ fontWeight: 300 }} title={typeInfo.name}>
                    {typeInfo.name}
                  </span>
                </ListItemButton>
              );
            })}
            {typeList.length > limit && (
              <CoreButton
                onClick={handleSeeMore}
                size={"small"}
                color={"secondary"}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ ml: "10px" }}
              >
                See More
              </CoreButton>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TypesOfFunnels;
