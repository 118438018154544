import httpRequest from "./api/httpRequest";
import TagI, { CreateTagI, UpdateTagI } from "../interface/tag.interface";

const funnelBuilderAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelBuilderAPI) throw Error("Funnel API URL not found in env!");

class FunnelTagService {
  fetchTag() {
    return httpRequest.get<TagI[]>(funnelBuilderAPI + "admin/funnel/tag/list");
  }

  createTag(payload: CreateTagI) {
    return httpRequest.post<TagI>(funnelBuilderAPI + "admin/funnel/tag/create", payload);
  }

  updateTag(payload: UpdateTagI) {
    return httpRequest.put<TagI>(funnelBuilderAPI + "admin/funnel/tag/update", payload);
  }

  deleteTag(tagId: string) {
    return httpRequest.delete<TagI>(funnelBuilderAPI + "admin/funnel/tag/delete/" + tagId);
  }
}

const funnelTagService = new FunnelTagService();
export default funnelTagService;
