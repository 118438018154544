import {Paper, styled} from "@mui/material";
import Box from "@mui/material/Box";

export const PageWrapperBox = styled(Box)(({ theme }) => ({
  minWidth: 280,
  maxWidth: 280,
  [theme.breakpoints.down("lg")]: {
    minWidth: 220,
    maxWidth: 255,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 180,
    maxWidth: 180,
  },
}));

export const PageImageBox = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: 300,
  objectFit: "cover",
  overflow: "hidden",
  margin: theme.spacing(2, 0),
  "& img": {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    height: 210,
  },
}));
