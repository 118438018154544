import httpRequest from "./api/httpRequest";
import CategoryI, { CreateCategoryI, UpdateCategoryI } from "../interface/category.interface";

const funnelBuilderAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelBuilderAPI) throw Error("Funnel API URL not found in env!");

class FunnelCategoryService {
  fetchCategory() {
    return httpRequest.get<CategoryI[]>(funnelBuilderAPI + "admin/funnel/category/list");
  }

  createCategory(payload: CreateCategoryI) {
    return httpRequest.post<CategoryI>(funnelBuilderAPI + "admin/funnel/category/create", payload);
  }

  updateCategory(payload: UpdateCategoryI) {
    return httpRequest.put<CategoryI>(funnelBuilderAPI + "admin/funnel/category/update", payload);
  }

  deleteCategory(categoryId: string) {
    return httpRequest.delete<CategoryI>(funnelBuilderAPI + "admin/funnel/category/delete/" + categoryId);
  }
}

const funnelCategoryService = new FunnelCategoryService();
export default funnelCategoryService;
