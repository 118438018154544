import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../state/store";
import { Box, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import GlobalTablePagination from "../../common/Pagination/GlobalTablePagination";
import { EmptyArchiveIcon, EmptyFormListIcon, EmptyTrashIcon } from "../../common/Icons/EmptyDataIcons";
import { deselectAllFunnel, getFunnelList, selectedAllFunnel } from "../../state/features/funnel/funnelSlice";
import { selectFunnelState } from "../../state/features/funnel/funnelSelector";
import FunnelListSkeleton from "../../common/LoadingView/FunnelListSkeleton";
import SingleFunnel from "./SingleFunnel";
import removeFalsyObjectProperty from "../../helpers/utils/removeFalsyObjectProperty";

const getEmptyPageContent = (
  archive: boolean,
  trash: boolean
): { icon: ReactElement; title: string; description: string } => {
  if (archive) {
    return {
      icon: <EmptyArchiveIcon />,
      title: "Archive is empty",
      description: "You can archive your funnels here if you want",
    };
  } else if (trash) {
    return {
      icon: <EmptyTrashIcon />,
      title: "Trash is Empty",
      description:
        "All funnels that you deleted will be visible here for 30 days. Within this interval you can restore your funnels again.",
    };
  } else {
    return {
      icon: <EmptyFormListIcon />,
      title: "No Funnels Found!",
      description:
        'You don\'t have any funnels. To create a new funnel, click the "Create Funnel" button at the top of the page.',
    };
  }
};

const TableHeader = ({
  onChange,
  checked,
  indeterminate,
  inTrash,
}: {
  onChange: () => void;
  checked: boolean;
  indeterminate: boolean;
  inTrash: boolean;
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              size={"small"}
              edge='start'
              color='secondary'
              checked={checked}
              indeterminate={indeterminate}
              onChange={onChange}
              sx={{
                padding: "4px",
                marginLeft: 0,
                marginRight: "4px",
                "& .PrivateSwitchBase-input": {
                  pointerEvents: "auto !important",
                },
              }}
            />
            <span>Funnel Name</span>
          </Stack>
        </TableCell>
        <TableCell align={"center"} width={180}>
          Total steps
        </TableCell>
        <TableCell>Last updated</TableCell>
        {!inTrash && <TableCell>Status</TableCell>}
        <TableCell align={"right"} sx={{ pr: 2 }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const FunnelListTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { data, isLoading, currentPage, limit, count, renderId, filters, selectedFunnelIds } =
    useSelector(selectFunnelState);
  const { searchText, orderBy, isArchived, inTrash } = filters;

  useEffect(() => {
    const payload = removeFalsyObjectProperty({
      currentPage,
      limit,
      searchText,
      orderBy,
      isArchived,
      inTrash,
    });
    dispatch(getFunnelList(payload));
  }, [searchText, orderBy, isArchived, inTrash, renderId]);

  const onChangePage = (newPage: number) => {
    const payload = removeFalsyObjectProperty({
      currentPage: newPage,
      limit: limit,
      searchText,
      orderBy,
      isArchived,
      inTrash,
    });
    dispatch(getFunnelList(payload));
  };

  const onChangePerPage = (perPage: number, newPage: number) => {
    const payload = removeFalsyObjectProperty({
      currentPage: newPage,
      limit: perPage,
      searchText,
      orderBy,
      isArchived,
      inTrash,
    });
    dispatch(getFunnelList(payload));
  };

  // decide what to render
  let content: null | JSX.Element = null;
  if (isLoading) {
    content = <FunnelListSkeleton count={8} />;
  } else if (data.length === 0) {
    const { icon, title, description } = getEmptyPageContent(isArchived, inTrash);
    content = <GlobalEmptyPage icon={icon} title={title} description={description} />;
  } else if (data.length > 0) {
    const selectAll = data.every((invoice) => selectedFunnelIds.includes(invoice.id as number));
    const indeterminate = !selectAll && selectedFunnelIds.length > 0;

    content = (
      <>
        <TableContainer sx={{ height: "calc(100% - 95px)", overflowY: "auto" }}>
          <Table stickyHeader>
            <TableHeader
              inTrash={inTrash}
              checked={selectAll}
              indeterminate={indeterminate}
              onChange={() => {
                selectAll ? dispatch(deselectAllFunnel()) : dispatch(selectedAllFunnel());
              }}
            />
            <TableBody>
              {data.map((funnelInfo) => (
                <SingleFunnel key={funnelInfo.id} funnelInfo={funnelInfo} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mb={-2.5}>
          <GlobalTablePagination
            count={count}
            page={currentPage}
            rowsPerPage={limit}
            onChangePage={onChangePage}
            onChangePerPage={onChangePerPage}
          />
        </Box>
      </>
    );
  }

  return content;
};

export default FunnelListTable;
