import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CategoryI, { CreateCategoryI, UpdateCategoryI } from "../../../interface/category.interface";

const initialState = {
  data: [] as CategoryI[],
  isLoading: false,

  isUpdating: false,
  isUpdatedSuccessfully: false,

  isCreating: false,
  isCreatedSuccessfully: false,

  isDeleting: false,
  isDeletedSuccessfully: false,
};

const funnelCategorySlice = createSlice({
  name: "funnelCategory",
  initialState,
  reducers: {
    fetchCategory(state) {
      state.isLoading = true;
    },
    fetchCategorySuccess(state, action: PayloadAction<CategoryI[]>) {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchCategoryFailed(state) {
      state.isLoading = false;
    },

    createCategory(state, action: PayloadAction<CreateCategoryI>) {
      state.isCreating = true;
    },
    createCategorySuccess(state, action: PayloadAction<CategoryI>) {
      state.isCreating = false;
      state.data.push(action.payload);
      state.isCreatedSuccessfully = true;
    },
    createCategoryFailed(state) {
      state.isCreating = false;
    },

    updateCategory(state, action: PayloadAction<UpdateCategoryI>) {
      state.isCreating = true;
    },
    updateCategorySuccess(state, action: PayloadAction<CategoryI>) {
      state.isCreating = false;
      state.data = state.data.map((category) => {
        if (category.id === action.payload.id) {
          return { ...category, ...action.payload };
        }
        return category;
      });
      state.isUpdatedSuccessfully = true;
    },
    updateCategoryFailed(state) {
      state.isCreating = false;
    },

    deleteCategory(state, action: PayloadAction<string>) {
      state.isDeleting = true;
    },
    deleteCategorySuccess(state, action: PayloadAction<string>) {
      state.isDeleting = false;
      state.data = state.data.filter((category) => {
        return category.id !== action.payload;
      });
      state.isDeletedSuccessfully = true;
    },
    deleteCategoryFailed(state) {
      state.isDeleting = false;
    },

    resetCreateSuccess(state) {
      state.isCreatedSuccessfully = false;
    },
    resetUpdateSuccess(state) {
      state.isUpdatedSuccessfully = false;
    },
  },
});

export const {
  createCategory,
  createCategoryFailed,
  createCategorySuccess,

  fetchCategory,
  fetchCategoryFailed,
  fetchCategorySuccess,

  updateCategory,
  updateCategoryFailed,
  updateCategorySuccess,

  deleteCategory,
  deleteCategoryFailed,
  deleteCategorySuccess,

  resetCreateSuccess,
  resetUpdateSuccess,
} = funnelCategorySlice.actions;
export default funnelCategorySlice.reducer;
