import React from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { getAccountData } from "../helpers/utils/getAccountData";

interface IProps {
  firstPath: string;
  secondPath: string;
  firstTitle: string;
  secondTitle: string;
}

const WebsiteFunnelSwitchingTabs: React.FC<IProps> = ({ firstTitle, secondTitle, firstPath, secondPath }) => {
  const history = useHistory();
  const [value, setValue] = React.useState(window.location.pathname);

  const handleChange = (event: React.SyntheticEvent, newValue: string | null) => {
    if (newValue !== null) {
      setValue(newValue);
      history.push(newValue);
    }
  };

  // view permission manage
  const accessibleAgencyIds = [1, 8];
  const accessibleUserIds = [1, 12, 15, 6991, 8229];

  const agencyId = getAccountData("agencyId");
  const userId = getAccountData("userId");

  const showBuilderSwitch =
    window.location.origin === "https://mf-parent.pypeprostaging.com" ||
    process.env.REACT_APP_PRODUCTION_MODE === "false" ||
    (accessibleAgencyIds.includes(agencyId) && accessibleUserIds.includes(userId));

  if (!showBuilderSwitch) return <></>;

  return (
    <Tabs value={value} onChange={handleChange} textColor={"secondary"} indicatorColor={"secondary"} sx={{ pb: 0.5 }}>
      <Tab value={firstPath} label={firstTitle} />
      <Tab value={secondPath} label={secondTitle} />
    </Tabs>
  );
};

export default WebsiteFunnelSwitchingTabs;
