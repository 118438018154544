import httpRequest from "./api/httpRequest";
import TypeI, { CreateTypeI, UpdateTypeI } from "../interface/type.interface";

const funnelBuilderAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelBuilderAPI) throw Error("Funnel API URL not found in env!");

class FunnelTypeService {
  fetchType() {
    return httpRequest.get<TypeI[]>(funnelBuilderAPI + "admin/funnel/type/list");
  }

  createType(payload: CreateTypeI) {
    return httpRequest.post<TypeI>(funnelBuilderAPI + "admin/funnel/type/create", payload);
  }

  updateType(payload: UpdateTypeI) {
    return httpRequest.put<TypeI>(funnelBuilderAPI + "admin/funnel/type/update", payload);
  }

  deleteType(typeId: string) {
    return httpRequest.delete<TypeI>(funnelBuilderAPI + "admin/funnel/type/delete/" + typeId);
  }
}

const funnelTypeService = new FunnelTypeService();
export default funnelTypeService;
