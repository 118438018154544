import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TagI, { CreateTagI, UpdateTagI } from "../../../interface/tag.interface";

const initialState = {
  data: [] as TagI[],
  isLoading: false,

  isUpdating: false,
  isUpdatedSuccessfully: false,

  isCreating: false,
  isCreatedSuccessfully: false,

  isDeleting: false,
  isDeletedSuccessfully: false,
};

const tagSlice = createSlice({
  name: "funnelTag",
  initialState,
  reducers: {
    fetchTag(state) {
      state.isLoading = true;
    },
    fetchTagSuccess(state, action: PayloadAction<TagI[]>) {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchTagFailed(state) {
      state.isLoading = false;
    },

    createTag(state, action: PayloadAction<CreateTagI>) {
      state.isCreating = true;
    },
    createTagSuccess(state, action: PayloadAction<TagI>) {
      state.isCreating = false;
      state.data.push(action.payload);
      state.isCreatedSuccessfully = true;
    },
    createTagFailed(state) {
      state.isCreating = false;
    },

    updateTag(state, action: PayloadAction<UpdateTagI>) {
      state.isCreating = true;
    },
    updateTagSuccess(state, action: PayloadAction<TagI>) {
      state.isCreating = false;
      state.data = state.data.map((tag) => {
        if (tag.id === action.payload.id) {
          return { ...tag, ...action.payload };
        }
        return tag;
      });
      state.isUpdatedSuccessfully = true;
    },
    updateTagFailed(state) {
      state.isCreating = false;
    },

    deleteTag(state, action: PayloadAction<string>) {
      state.isDeleting = true;
    },
    deleteTagSuccess(state, action: PayloadAction<string>) {
      state.isDeleting = false;
      state.data = state.data.filter((tag) => {
        return tag.id !== action.payload;
      });
      state.isDeletedSuccessfully = true;
    },
    deleteTagFailed(state) {
      state.isDeleting = false;
    },

    resetCreateSuccess(state) {
      state.isCreatedSuccessfully = false;
    },
    resetUpdateSuccess(state) {
      state.isUpdatedSuccessfully = false;
    },
  },
});

export const {
  createTag,
  createTagFailed,
  createTagSuccess,

  fetchTag,
  fetchTagFailed,
  fetchTagSuccess,

  updateTag,
  updateTagFailed,
  updateTagSuccess,

  deleteTag,
  deleteTagFailed,
  deleteTagSuccess,

  resetCreateSuccess,
  resetUpdateSuccess,
} = tagSlice.actions;
export default tagSlice.reducer;
