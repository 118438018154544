import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Stack, Typography } from "@mui/material";

import CoreButton from "../../../../common/Button/CoreButton";
import { fetchTag } from "../../../../state/features/funnel-tag/funnelTagSlice";
import { selectFunnelTagState } from "../../../../state/features/funnel-tag/funnelTagSelector";
import { toggleTag } from "../../../../state/features/funnel-template-library/funnelTemplateLibrarySlice";
import { selectFunnelTemplateLibraryFilterState } from "../../../../state/features/funnel-template-library/funnelTemplateLibrarySelector";

const FunnelTags: React.FC = () => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(5);
  const [expanded, setExpanded] = useState(false);
  const { data: tagList } = useSelector(selectFunnelTagState);
  const { tagIds } = useSelector(selectFunnelTemplateLibraryFilterState);

  const handleSeeMore = () => {
    setLimit((prevState) => prevState + 5);
  };

  useEffect(() => {
    dispatch(fetchTag());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTag = (tagId: string) => {
    dispatch(toggleTag(tagId));
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ background: "transparent", boxShadow: "none", "&:before": { content: "none" } }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          sx={{
            p: 0,
            minHeight: "36px !important",
            "& .MuiAccordionSummary-content.Mui-expanded": { margin: "12px 0" },
          }}
        >
          <Typography variant={"body2SemiBold"} color={"text.secondary"}>
            Tags
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Stack direction={"row"} flexWrap={"wrap"} sx={{ gap: "8px" }}>
            {tagList.map((tagInfo, index) => {
              const isChecked = tagIds.indexOf(tagInfo.id) !== -1;
              return (
                <Chip
                  key={tagInfo.id}
                  size={"small"}
                  variant={"filled"}
                  color={isChecked ? "primary" : "default"}
                  label={tagInfo.name}
                  onClick={() => handleSelectTag(tagInfo.id)}
                  sx={{ fontWeight: 300 }}
                />
              );
            })}
            {tagList.length > limit && (
              <CoreButton
                onClick={handleSeeMore}
                size={"small"}
                color={"secondary"}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ ml: "10px" }}
              >
                See More
              </CoreButton>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FunnelTags;
