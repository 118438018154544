import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import { format, isDate } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import { Chip, styled, TableCell } from "@mui/material";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileCopyOutlined } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import AddOrEditPageInfo from "./AddOrEditPageInfo";
import RouteLink from "../../common/Link/RouteLink";
import useBoolean from "../../hooks/useBoolean";
import IconButton from "../../common/IconButton";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import { generateEditorUrl } from "../../helpers/utils/generateEditorUrl";
import { goToPreviewPage } from "../../helpers/utils/goToPreviewPage";
import IWebsitePage from "../../state/features/website-pages/websitePage.interface";
import { selectWebsiteSettingState } from "../../state/features/website-settings/websiteSettingSelector";
import {
  useCloneWebSitePageMutation,
  useDeleteWebSitePageMutation,
} from "../../state/features/website-pages/websitePageApiSlice";

//TODO replace the url below to our S3 CDN
const CardImg = styled(Box)<{ thumbnail: string }>(({ theme, thumbnail }) => ({
  height: "180px",
  width: "100%",
  background: "radial-gradient(circle, rgba(174,217,238,1) 0%, rgba(148,187,233,1) 100%)",
  backgroundImage: `url(${thumbnail || "https://www.messagetech.com/wp-content/themes/ml_mti/images/no-image.jpg"})`,
  backgroundPosition: "top",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`,
}));

interface IProps {
  pageInfo: IWebsitePage;
}

const SingleWebPageDetails: React.FC<IProps> = ({ pageInfo }) => {
  const { pageId, name, websiteId, path, metadata, createdAt = "" } = pageInfo;
  const { author, title, description } = metadata || {};
  const { data: websiteDetails } = useSelector(selectWebsiteSettingState);
  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const { value: openPageModal, setFalse: onClosePageModal, setTrue: onOpenPageModal } = useBoolean(false);

  const [cloneWebSitePage] = useCloneWebSitePageMutation();
  const [deleteWebsitePage, { isSuccess, isLoading: isDeleting }] = useDeleteWebSitePageMutation();

  useEffect(() => {
    if (isSuccess) {
      onCloseDeleteModal();
    }
  }, [isSuccess]);

  const deletePageHandler = () => {
    deleteWebsitePage({ pageId: pageId, websiteId: websiteId });
  };

  const clonePageHandler = () => {
    cloneWebSitePage({ pageId, websiteId });
  };

  const handleGotoPreview = () => {
    if (websiteDetails.domainInfo) {
      const addSlash = path.startsWith("/") ? "" : "/";
      window.open("https://" + websiteDetails.domainInfo.domain + addSlash + path, "_blank");
      return;
    }

    goToPreviewPage(pageId, websiteId);
  };

  const formattedCreatedAt =
    createdAt && isDate(new Date(createdAt)) ? format(new Date(createdAt), "MMM dd, yyyy") : "-";

  return (
    <>
      <TableCell>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <RouteLink to={generateEditorUrl(pageId, websiteId)}>
            <Typography
              variant={"body1SemiBold"}
              color={"text.primary"}
              noWrap
              textTransform={"capitalize"}
              fontWeight={400}
            >
              {name}
            </Typography>
          </RouteLink>

          {pageInfo.isDefault === 1 && (
            <CustomTooltip title={"Default Page"} arrow placement={"top"}>
              <Chip label='Default' size='small' color={"primary"} sx={{ height: "20px", fontSize: "10px" }} />
            </CustomTooltip>
          )}
        </Stack>
      </TableCell>
      <TableCell>{author ? author : "-"}</TableCell>
      <TableCell>
        <Typography variant={"caption"}>
          Published <br /> {formattedCreatedAt}
        </Typography>
      </TableCell>
      <TableCell>{title ? title : "-"}</TableCell>
      <TableCell>{description ? description : "-"}</TableCell>

      <TableCell align={"right"}>
        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
          <RouteLink to={generateEditorUrl(pageId, websiteId)}>
            <CustomTooltip title={"Edit"} arrow placement={"top"}>
              <IconButton variant={"outlined"} color={"secondary"} size='small'>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </CustomTooltip>
          </RouteLink>

          <CustomTooltip title={"Update Page"} arrow placement={"top"}>
            <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={onOpenPageModal}>
              <SettingsIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title={"Clone"} arrow placement={"top"}>
            <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={clonePageHandler}>
              <FileCopyOutlined sx={{ fontSize: "16px" }} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title={"Preview"} arrow placement={"top"}>
            <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={handleGotoPreview}>
              <LaunchIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </CustomTooltip>

          {pageInfo.isDefault === 0 && (
            <CustomTooltip title={"Delete"} arrow placement={"top"}>
              <IconButton variant={"outlined"} color={"error"} size='small' onClick={onOpenDeleteModal}>
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </CustomTooltip>
          )}
        </Stack>
      </TableCell>

      <BasicModalWithHeader open={openPageModal} onClose={onClosePageModal} title={"Update Page Settings"} width={500}>
        <AddOrEditPageInfo onClose={onClosePageModal} pageInfo={pageInfo} isEdit={true} />
      </BasicModalWithHeader>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={deletePageHandler}
      />
    </>
  );
};

export default SingleWebPageDetails;
