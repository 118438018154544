import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  FunnelTemplateStepI,
  CloneFunnelTemplateStepPayloadI,
  CreateFunnelTemplateStepPayloadI,
  DeleteFunnelTemplateStepPayloadI,
  UpdateFunnelTemplateStepPayloadI,
  ReorderFunnelTemplateStepPayloadI,
} from "../../../interface/funnelTemplateStep.interface";

const initialState = {
  data: [] as FunnelTemplateStepI[],
  isLoading: false,
  isSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,

  isCloning: false,
  isClonedSuccess: false,

  isAdding: false,
  isAddSuccess: false,

  createdStepId: null as null | string,
  createdPageId: null as null | string,
  selectedFunnelStep: {} as FunnelTemplateStepI,
};

const funnelTemplateStepSlice = createSlice({
  name: "funnelTemplateStep",
  initialState,
  reducers: {
    fetchFunnelTemplateSteps(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    fetchFunnelTemplateStepsSuccess(state, action: PayloadAction<FunnelTemplateStepI[]>) {
      state.data = action.payload;
      state.isSuccess = true;
      state.isLoading = false;
    },
    resetFetchFunnelTemplateSteps(state) {
      state.isLoading = false;
      state.isSuccess = false;
    },

    addFunnelTemplateStep(state, action: PayloadAction<CreateFunnelTemplateStepPayloadI>) {
      state.isAdding = true;
    },
    addFunnelTemplateStepSuccess(state, action: PayloadAction<FunnelTemplateStepI>) {
      state.isAdding = false;
      state.isAddSuccess = true;
      state.data.push(action.payload);
      state.createdStepId = action.payload.stepId;
      state.createdPageId = action.payload.pageId;
    },
    resetAddFunnelTemplateStep(state) {
      state.isAdding = false;
      state.isAddSuccess = false;
      state.createdPageId = "";
    },

    cloneFunnelTemplateStep(state, action: PayloadAction<CloneFunnelTemplateStepPayloadI>) {
      state.isCloning = true;
    },
    cloneFunnelTemplateStepSuccess(state, action: PayloadAction<FunnelTemplateStepI>) {
      state.isCloning = false;
      state.isClonedSuccess = true;
      state.data.push(action.payload);
    },
    resetCloneFunnelTemplateStep(state) {
      state.isCloning = false;
      state.isClonedSuccess = false;
    },

    updateFunnelTemplateStep(state, action: PayloadAction<UpdateFunnelTemplateStepPayloadI>) {
      state.isUpdating = true;
    },
    updateFunnelTemplateStepSuccess(state, action: PayloadAction<UpdateFunnelTemplateStepPayloadI>) {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.data = state.data.map((step) => {
        if (step.stepId === action.payload.stepId) {
          return { ...step, ...action.payload };
        }
        return step;
      });
    },
    resetUpdateFunnelTemplateStep(state) {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },

    reorderFunnelTemplateStep: (state, action: PayloadAction<ReorderFunnelTemplateStepPayloadI>) => {
      const cloneData = [...state.data];
      const deleteStep = cloneData.splice(action.payload.sourceIndex, 1);
      cloneData.splice(action.payload.targetIndex, 0, deleteStep[0]);
      state.data = cloneData;
    },
    reorderFunnelTemplateStepSuccess: (_state) => {},
    resetReorderFunnelTemplateStepSuccess: (state, action: PayloadAction<ReorderFunnelTemplateStepPayloadI>) => {
      const cloneData = [...state.data];
      const deleteStep = cloneData.splice(action.payload.targetIndex, 1);
      cloneData.splice(action.payload.sourceIndex, 0, deleteStep[0]);
      state.data = cloneData;
    },

    deleteFunnelTemplateStep(state, action: PayloadAction<DeleteFunnelTemplateStepPayloadI>) {
      state.isDeleting = true;
    },
    deleteFunnelTemplateStepSuccess(state, action: PayloadAction<DeleteFunnelTemplateStepPayloadI>) {
      state.isDeleting = false;
      state.data = state.data.filter(({ stepId }) => {
        return parseInt(stepId) !== parseInt(action.payload.stepId);
      });
    },
    resetDeleteFunnelTemplateStep(state) {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },

    setSelectedFunnelTemplateStep(state, action: PayloadAction<FunnelTemplateStepI>) {
      state.selectedFunnelStep = action.payload;
    },
  },
});

export const {
  fetchFunnelTemplateSteps,
  fetchFunnelTemplateStepsSuccess,
  resetFetchFunnelTemplateSteps,
  addFunnelTemplateStep,
  addFunnelTemplateStepSuccess,
  resetAddFunnelTemplateStep,
  cloneFunnelTemplateStep,
  cloneFunnelTemplateStepSuccess,
  resetCloneFunnelTemplateStep,
  updateFunnelTemplateStep,
  updateFunnelTemplateStepSuccess,
  resetUpdateFunnelTemplateStep,
  deleteFunnelTemplateStep,
  deleteFunnelTemplateStepSuccess,
  resetDeleteFunnelTemplateStep,
  setSelectedFunnelTemplateStep,
  reorderFunnelTemplateStep,
  reorderFunnelTemplateStepSuccess,
  resetReorderFunnelTemplateStepSuccess,
} = funnelTemplateStepSlice.actions;
export default funnelTemplateStepSlice.reducer;
