import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TypeI, { CreateTypeI, UpdateTypeI } from "../../../interface/type.interface";

const initialState = {
  data: [] as TypeI[],
  isLoading: false,

  isUpdating: false,
  isUpdatedSuccessfully: false,

  isCreating: false,
  isCreatedSuccessfully: false,

  isDeleting: false,
  isDeletedSuccessfully: false,
};

const funnelTypeSlice = createSlice({
  name: "funnelType",
  initialState,
  reducers: {
    fetchType(state) {
      state.isLoading = true;
    },
    fetchTypeSuccess(state, action: PayloadAction<TypeI[]>) {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchTypeFailed(state) {
      state.isLoading = false;
    },

    createType(state, action: PayloadAction<CreateTypeI>) {
      state.isCreating = true;
    },
    createTypeSuccess(state, action: PayloadAction<TypeI>) {
      state.isCreating = false;
      state.data.push(action.payload);
      state.isCreatedSuccessfully = true;
    },
    createTypeFailed(state) {
      state.isCreating = false;
    },

    updateType(state, action: PayloadAction<UpdateTypeI>) {
      state.isCreating = true;
    },
    updateTypeSuccess(state, action: PayloadAction<TypeI>) {
      state.isCreating = false;
      state.data = state.data.map((type) => {
        if (type.id === action.payload.id) {
          return { ...type, ...action.payload };
        }
        return type;
      });
      state.isUpdatedSuccessfully = true;
    },
    updateTypeFailed(state) {
      state.isCreating = false;
    },

    deleteType(state, action: PayloadAction<string>) {
      state.isDeleting = true;
    },
    deleteTypeSuccess(state, action: PayloadAction<string>) {
      state.isDeleting = false;
      state.data = state.data.filter((type) => {
        return type.id !== action.payload;
      });
      state.isDeletedSuccessfully = true;
    },
    deleteTypeFailed(state) {
      state.isDeleting = false;
    },

    resetCreateSuccess(state) {
      state.isCreatedSuccessfully = false;
    },
    resetUpdateSuccess(state) {
      state.isUpdatedSuccessfully = false;
    },
  },
});

export const {
  createType,
  createTypeFailed,
  createTypeSuccess,

  fetchType,
  fetchTypeFailed,
  fetchTypeSuccess,

  updateType,
  updateTypeFailed,
  updateTypeSuccess,

  deleteType,
  deleteTypeFailed,
  deleteTypeSuccess,

  resetCreateSuccess,
  resetUpdateSuccess,
} = funnelTypeSlice.actions;
export default funnelTypeSlice.reducer;
